exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-match-booking-js": () => import("./../../../src/pages/match-booking.js" /* webpackChunkName: "component---src-pages-match-booking-js" */),
  "component---src-pages-my-team-js": () => import("./../../../src/pages/my-team.js" /* webpackChunkName: "component---src-pages-my-team-js" */),
  "component---src-pages-myaccount-js": () => import("./../../../src/pages/myaccount.js" /* webpackChunkName: "component---src-pages-myaccount-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-tournaments-js": () => import("./../../../src/pages/tournaments.js" /* webpackChunkName: "component---src-pages-tournaments-js" */)
}

